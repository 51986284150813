$grid-breakpoints: (
  xs: 0,
  xsm: 320px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
) !default;

$flag-icon-css-path: '/assets/icon/flags' !default;
$flag-icon-width: 30px !default;
