$fontello-woff-font-path: '/assets/fonts/cf/fontello.woff';

@font-face {
  font-family: 'fontcf';
  src: url($fontello-woff-font-path);
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^='cf-']::before,
[class*=' cf-']::before {
  font-family: fontcf, serif !important;
}

.cf-icon-crowdfarmer::before {
  content: '\e810';
}

.cf-icon-tree::before {
  content: '\e811';
}

.cf-icon-farmer::before {
  content: '\e812';
}

.cf-icon-box::before {
  content: '\e813';
}

.cf-icon-location::before {
  content: '\e814';
}

.cf-icon-calendar::before {
  content: '\e815';
}

.cf-icon-drop::before {
  content: '\e816';
}

.cf-icon-shovel::before {
  content: '\e817';
}

.cf-icon-crowdfarming::before {
  content: '\e818';
}

.cf-icon-crowdfarming-bold::before {
  content: '\e841';
}

.cf-icon-text-light::before {
  content: '\e83c';
}

.cf-icon-text-bold::before {
  content: '\e83b';
}

.cf-icon-crowdfarming-old::before {
  content: '\e83a';
}

.cf-icon-masterBox::before {
  content: '\e820';
}

.cf-icon-bin::before {
  content: '\e821';
}

.cf-icon-eco::before {
  content: '\e822';
}

.cf-icon-up::before {
  content: '\e823';
}

.cf-icon-adopt::before {
  content: '\e824';
}

.cf-icon-meetFarmers::before {
  content: '\e825';
}

.cf-icon-nameUp::before {
  content: '\e826';
}

.cf-icon-shipment::before {
  content: '\e827';
}

.cf-icon-cart::before {
  content: '\e828';
}

.cf-icon-paperPlane::before {
  content: '\e829';
}

.cf-icon-sheep::before {
  content: '\e842';
}

.cf-icon-farm::before {
  content: '\e843';
}

.cf-icon-height::before {
  content: '\e844';
}

.icon {
  &Big100 {
    display: inline-block;
    font-size: 100px;
    line-height: 104px;
  }
}
