/* You can add global styles to this file, and also import other style files */

@import './scss/commons.scss';
@import './scss/helpers.scss';
@import './scss/fonts.scss';
@import './assets/icon/scss/_eva-icons-font.scss';
@import './scss/general.scss';
@import './scss/flags.scss';
@import './scss/bootstrap/bootstrap-reboot.scss';
@import './scss/bootstrap/bootstrap-grid.scss';
@import './scss/bootstrap/utilities/_position.scss';
@import './scss/bootstrap/utilities/_sizing.scss';
@import '@crowdfarming/ds-library/src/styles/global.scss';

body {
  font-family: 'Montserrat', Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  font-weight: 400;
  color: $CF1;
  background-color: $white;
  min-height: calc(100vh - 70px);

  &.no-scroll {
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.page-margin {
  max-width: 1216px;
  margin: 0 auto;
  color: $ds--color-primary-88;

  @media (max-width: 1439px) {
    max-width: 1279px;
    margin-left: 80px;
    margin-right: 80px;
  }

  @media (max-width: 1279px) {
    margin-left: 60px;
    margin-right: 60px;
  }

  @media (max-width: 959px) {
    margin-left: 24px;
    margin-right: 24px;
  }

  @media (max-width: 599px) {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.page-margin-top {
  margin-top: 72px;

  @media (max-width: 1279px) {
    margin-top: 60px;
  }

  @media (max-width: 959px) {
    margin-top: 24px;
  }

  @media (max-width: 599px) {
    margin-top: 16px;
  }
}
