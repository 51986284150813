/* Centering */
.center-total {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-horizontal {
  display: flex;
  justify-content: center;
}

.center-vertical {
  display: flex;
  align-items: center;
}

.align-vertical {
  text-align: center;
}

/* Columns */
.column-75 {
  width: 75%;
  float: left;
}

.column-25 {
  width: 25%;
  float: left;
}

/* Text */
/* Padding */
.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}